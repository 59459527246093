import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import GoogleReviews from "../../images/google-reviews.svg"


const GoogleReview = (props) => {

    const data = useStaticQuery(graphql`
    query {
        allGoogleReviews(filter: {brand_name: {eq: "wards"}}) {
            edges {
              node {
                averageRating
                brand_name
                totalReviewCount
              }
            }
        }
    }
  `);

    const reviews = data.allGoogleReviews.edges;

    return (
        <React.Fragment>
            <div className="review-logo">
                <img src={GoogleReviews} alt="" width="74" height="30" />
            </div>
            <p>
                <strong>{(reviews[0].node.averageRating).toFixed(1)} out of 5 </strong>
                based on {reviews[0].node.totalReviewCount.toLocaleString()} reviews
            </p>
        </React.Fragment>
    );
};

export default GoogleReview;
